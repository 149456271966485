import React from 'react';

// libs
import { GSAP } from '@lib';

// components
import DynamicComponent from '@components/DynamicComponent';

// layout
import Layout from '@layout';

// custom hook
import useStoryblok, { isStory } from '@hooks/useStoryblok';

export default function StoryblokEntry({ pageContext, location }: any): JSX.Element {
  const [story] = useStoryblok(pageContext);

  if (!isStory(story)) return <div />;

  if (story.content.component === 'page') {
    // TODO: Breaks for coming-soon page
    // GSAP.initAnimation();
  }

  return (
    <Layout location={location}>
      <DynamicComponent blok={story.content} key={story.uuid} />
    </Layout>
  );
}
